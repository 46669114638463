const AppSetting = {
    MarketReport: process.env.VUE_APP_BACK_URL_ENV + "Report/MarketReport",
    GetOldReports: process.env.VUE_APP_BACK_URL_ENV + "Report/GetReports",
    GetReports: process.env.VUE_APP_BACK_URL_ENV + "Report/GetReportHistoryWithPaging",
    GetSampleReports: process.env.VUE_APP_BACK_URL_ENV + "Report/GetSampleReports",

    GetIndustryList:process.env.VUE_APP_BACK_URL_ENV + "Industry/GetList",

    SearchAddress: process.env.VUE_APP_BACK_URL_ENV + "Map/SearchAddress",

    LoginDb: process.env.VUE_APP_BACK_URL_ENV + "Account/Login",
    RegisterDb: process.env.VUE_APP_BACK_URL_ENV + "Account/Register",
    RegisterTerms: process.env.VUE_APP_BACK_URL_ENV + "Account/GetRegisterTerms",
    ReSendMailDb: process.env.VUE_APP_BACK_URL_ENV + "Account/ReSendMail",
    ValidToken: process.env.VUE_APP_BACK_URL_ENV + "Account/ValidToken",

    MyInfo: process.env.VUE_APP_BACK_URL_ENV + "Account/MyInfo",
    UpdateMyInfo: process.env.VUE_APP_BACK_URL_ENV + "Account/UpdateMyInfo",

    AddToCar: process.env.VUE_APP_BACK_URL_ENV + "Billing/Buy",
    GetLicenseSeatCount: process.env.VUE_APP_BACK_URL_ENV + "Billing/GetLicenseSeats",
    GetBilling: process.env.VUE_APP_BACK_URL_ENV + "Billing/GetBillingHistoryWithPaging",
    GetProducts: process.env.VUE_APP_BACK_URL_ENV + "Billing/GetAllProducts",

    Feedback: process.env.VUE_APP_BACK_URL_ENV + "Feedback/SendFeedback",

    AddVoucher: process.env.VUE_APP_BACK_URL_ENV + "Voucher/AddVoucher",
    GetVoucherList: process.env.VUE_APP_BACK_URL_ENV + "Voucher/GetVoucherWithPaging",
    GetVoucherDetailList: process.env.VUE_APP_BACK_URL_ENV + "Voucher/GetVoucherDetailWithPaging",
    VoucherList: process.env.VUE_APP_BACK_URL_ENV + "Voucher/VoucherList",
    GetRedeemCodeTerms: process.env.VUE_APP_BACK_URL_ENV + "Voucher/GetRedeemCodeTerms",
    GetAdList: process.env.VUE_APP_BACK_URL_ENV + "Advertisement/GetAd",

    MySurveycake: process.env.VUE_APP_SURVERYCAKE_URL,

    GetAudience: process.env.VUE_APP_BACK_URL_ENV + "Account/GetAudienceInfo",
    UpdateAudience: process.env.VUE_APP_BACK_URL_ENV + "Account/UpdateAudienceInfo",

    GetCompetitorList: process.env.VUE_APP_BACK_URL_ENV + "ManualReport/UserBrandsList",
    GetAdl2List: process.env.VUE_APP_BACK_URL_ENV + "ManualReport/GetAdl2List",
    GetAdl5List: process.env.VUE_APP_BACK_URL_ENV + "ManualReport/GetAdl5List",

    PerPage: "10",
    Online: process.env.VUE_APP_ONLINE,

    IsSysMaintain: "false",
    SysMaintainDatetime: "2020.07.13(一) 9:00 ~ 18:00 ",

    DropDownList:{
        PriceList: [{label:'小於 150 元',value:150},{label:'150 - 300 元',value:300},{label:'300 - 500 元',value:500},{label:'500 - 1,000 元',value:1000},{label:'1,000 - 3,000 元',value:3000},{label:'大於 3,000 元',value:10000}],
        RadiusList:[{label:'250m',value:250},{label:'500m',value:500},{label:'1km',value:1000},{label:'2km',value:2000},{label:'3km',value:3000},{label:'4km',value:4000},{label:'5km',value:5000}],
    },

    AdvancedReportChannelID: "B27C60DF-6123-4807-B81E-FC0447010C65",
};


export default AppSetting;
